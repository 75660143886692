// src/components/PromptInput.js
import React from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const clientStatusOrder = [
  "Inhouse",
  "Incoming",
  "clinical assessment",
  "aftercare",
];
const statusColors = {
  Inhouse: "green",
  Incoming: "blue",
  "clinical assessment": "orange",
  aftercare: "purple",
  Others: "gray", // Pour les statuts non définis dans clientStatusOrder
};

const PromptInputDesktop = ({
  prompt,
  handleInputChange,
  handleSendClick,
  handleLogPrompt,
  handleInputKeyPress,
  selectedClient,
  handleClientChange,
  clientsNotAnonymized,
  remainingPrompts,
  promptTokens,
  responseTokens,
  tokenUsagePercentage,
  totalTokens,
  disabledSend,
}) => {
  return (
    <div
      style={{
        width: "70%",
        paddingLeft: "0px",
        paddingRight: "0px",
        marginBottom: "100px",
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <span
        style={{
          fontWeight: "bold",
          color: "red",
          textAlign: "center",
          fontSize: "10px",
        }}
      >
        {remainingPrompts > 0
          ? `**Demo version** - Remaining prompts: ${remainingPrompts}`
          : "You've used all the credits of the demo version"}
      </span>
      <TextField
        variant="outlined"
        rows={prompt.length > 0 ? 4 : 1}
        multiline
        onChange={handleInputChange}
        value={prompt}
        fullWidth
        onKeyPress={handleInputKeyPress}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "0px",
          alignContent: "center",
          alignItems: "center",
          paddingTop: "5px",
        }}
      >
        {/* Client Selection */}
        <div
          style={{
            minWidth: 200,
            marginRight: "20px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <label
            htmlFor="client-select"
            style={{
              fontWeight: "500",
              paddingRight: "5px",
              width: "130px",
              textAlign: "right",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            Search on
          </label>
          <select
            id="client-select"
            value={selectedClient}
            onChange={handleClientChange}
            style={{
              width: "100%",
              // padding: "10px",
              padding: "5px 10px", // Réduire le padding vertical
              borderRadius: "5px",
              border: "1px solid #ccc",
              backgroundColor: "#fff",
              fontSize: "14px",
              height: "30px",
              overflow: "auto",
            }}
          >
            <option value="All Clients">All Clients</option>
            {clientsNotAnonymized.map((client) => (
              <option key={client.id} value={client.id}>
                {client.firstName} {client.lastName}
              </option>
            ))}
          </select>
        </div>

        {/* Remaining Prompts */}

        {/* Token Usage */}

        {/* Send Button */}
        <div
          style={{
            backgroundColor: disabledSend
              ? "lightgray"
              : prompt.length > 0
              ? "#1565C0"
              : "white",
            paddingLeft: "12px",
            paddingRight: "8px",
            paddingTop: "8px",
            paddingBottom: "5px",
            borderRadius: "5px",
          }}
        >
          <SendIcon
            style={{
              cursor: disabledSend ? "not-allowed" : "pointer",
              color: disabledSend
                ? "gray"
                : prompt.length > 0
                ? "white"
                : "gray",
            }}
            onClick={disabledSend ? null : handleSendClick}
          />
        </div>

        {/* Log Prompt Button */}
      </div>
      <div
        style={{
          fontSize: "12px",
          color: "gray",
          marginTop: "0px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div>
          Prompt size: {promptTokens} tokens (
          <span
            style={{
              fontWeight: "bold",
              color: (promptTokens / 128000) * 100 > 80 ? "red" : "green",
            }}
          >
            {((promptTokens / 128000) * 100).toFixed(2)}%
          </span>{" "}
          of max 128000)
        </div>
        <div
          style={{ fontWeight: "900", paddingLeft: "5px", paddingRight: "5px" }}
        >
          |
        </div>
        <div>
          Response size: {responseTokens} tokens (
          <span
            style={{
              fontWeight: "bold",
              color: (responseTokens / 16000) * 100 > 80 ? "red" : "green",
            }}
          >
            {((responseTokens / 16000) * 100).toFixed(2)}%
          </span>{" "}
          of max 16000)
        </div>
        <div
          style={{ fontWeight: "900", paddingLeft: "5px", paddingRight: "5px" }}
        >
          |
        </div>
        <div>
          Total size: {totalTokens} tokens (
          <span
            style={{
              fontWeight: "bold",
              color: tokenUsagePercentage > 80 ? "red" : "green",
            }}
          >
            {tokenUsagePercentage.toFixed(2)}%
          </span>{" "}
          of max 128000)
        </div>
        {tokenUsagePercentage > 80 && tokenUsagePercentage <= 100 && (
          <Typography variant="body2" color="error">
            Warning: You are approaching the maximum token limit!
          </Typography>
        )}
        {tokenUsagePercentage > 100 && (
          <Typography
            variant="body2"
            color="error"
            style={{ marginTop: "10px" }}
          >
            Error: The total token count exceeds the maximum limit of 128,000
            tokens. Please shorten your prompt or wait for a response before
            asking another question.
          </Typography>
        )}
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div style={{ width: "20%", paddingTop: "10px" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleLogPrompt}
            style={{ marginLeft: "5px" }}
          >
            Log Prompt
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PromptInputDesktop;
