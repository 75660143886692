import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Slide,
  TextField,
  CircularProgress,
  Autocomplete,
  Avatar,
  Input,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../contexts/AuthContext";
import { showCustomToast } from "../Misc/CustomToast";
import DatePickerWithTimeComponentNoTitle from "../DatePickerWithTimeComponentNoTitle";
import UploaderDocForNoteV2 from "../Updloader/UploaderDocForNoteV2";
import InputComponentNoTitleNomargin from "../InputComponentNoTitleNomargin";
import DropDownComponentSortedNoTitle from "../DropDownComponentSortedNoTitle";
import { LanguageContext } from "../../context/LanguageContext";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  collection,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import DatePickerWithTimeComponentNoTitleNoMargin from "../DatePickerWithTimeComponentNoTitleNoMargin";
import SearchBarInModal from "../SearchBarInModal";
import SmallClientChipV3Large from "../SmallClientChipV3Large";
import DropDownSpecificStaffV2 from "../Dropdowns/DropDownSpecificStaffV2";

const NotesAddModalClientSelected = ({
  open,
  onClose,
  noteType,
  handleSave,
  transferedClient,
}) => {
  const { translate } = useContext(LanguageContext);
  const { currentUser } = useAuth();
  const [noteContent, setNoteContent] = useState("");
  const [effectiveDateTime, setEffectiveDateTime] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Example state for a specific note type
  const [shiftStartRaw, setShiftStartRaw] = useState(null);
  const [shiftEndRaw, setShiftEndRaw] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState("");
  const [staff, setStaff] = useState("");
  const [groupTopic, setGroupTopic] = useState("");
  const [groupType, setGroupType] = useState("");
  const [otherTypeGroupNote, setOtherTypeGroupNote] = useState("");
  const [relationship, setRelationship] = useState("");
  const [notInAttendance, setNotInAttendance] = useState("");
  const [immigrationAppointment, setImmigrationAppointment] = useState("");
  const [dischargeSwot, setDischargeSwot] = useState("");
  const [newClients, setNewClients] = useState("");
  const [medicalInfos, setMedicalInfos] = useState("");
  const [hospitalAppointment, setHospitalAppointment] = useState("");
  const [residentialClientMovements, setResidentialClientMovements] =
    useState("");
  const [zoomSessionAssistance, setZoomSessionAssistance] = useState("");
  const [staffToday, setStaffToday] = useState("");
  const [overnight, setOvernight] = useState("");
  const [numberOfClient, setNumberOfClient] = useState("");
  const [swot, setSwot] = useState("");
  const [generalAnnouncement, setGeneralAnnouncement] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(
    transferedClient || null
  );

  const [selectedClientName, setSelectedClientName] = useState("");
  const [unsubscribe, setUnsubscribe] = useState(null);
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const [errors, setErrors] = useState({});

  const validateFields = () => {
    const newErrors = {};

    switch (noteType) {
      case "aftercareNote":
      case "medicalNote":
      case "quickNote":
      case "familyNote":
      case "supportStaffNote":
      case "therapyNote":
        if (!selectedClient)
          newErrors.clientName = translate("ClientNameRequired");
        if (!noteContent) newErrors.noteContent = translate("ContentRequired");
        if (!effectiveDateTime)
          newErrors.effectiveDateTime = translate("EffectiveDateRequired");
        break;
      case "handoverNote":
        if (!immigrationAppointment)
          newErrors.immigrationAppointment = translate(
            "ImmigrationAppointmentRequired"
          );
        if (!dischargeSwot)
          newErrors.dischargeSwot = translate("DischargesFlightsRequired");
        if (!newClients) newErrors.newClients = translate("NewClientsRequired");
        if (!medicalInfos)
          newErrors.medicalInfos = translate(
            "PsychiatristAppointmentsRequired"
          );
        if (!hospitalAppointment)
          newErrors.hospitalAppointment = translate(
            "HospitalAppointmentsRequired"
          );
        if (!residentialClientMovements)
          newErrors.residentialClientMovements = translate("OutingsRequired");
        if (!zoomSessionAssistance)
          newErrors.zoomSessionAssistance = translate(
            "ZoomSessionAssistanceRequired"
          );
        if (!staffToday) newErrors.staffToday = translate("StaffTodayRequired");
        if (!overnight) newErrors.overnight = translate("OvernightRequired");
        if (!numberOfClient)
          newErrors.numberOfClient = translate("NumberOfClientsRequired");
        if (!swot) newErrors.swot = translate("SwotRequired");
        if (!generalAnnouncement)
          newErrors.generalAnnouncement = translate(
            "GeneralAnnouncementRequired"
          );
        if (!effectiveDateTime)
          newErrors.effectiveDateTime = translate("EffectiveDateRequired");
        break;
      case "groupNote":
        if (!groupTopic) newErrors.groupTopic = translate("GroupTopicRequired");
        if (!effectiveDateTime)
          newErrors.effectiveDateTime = translate("StartTimeRequired");
        if (!groupType) newErrors.groupType = translate("GroupTypeRequired");
        if (!noteContent) newErrors.noteContent = translate("ContentRequired");
        if (!notInAttendance)
          newErrors.notInAttendance = translate("NotInAttendanceRequired");
        break;
      case "endOfShift":
        if (!noteContent) newErrors.noteContent = translate("ContentRequired");
        if (!shiftStartRaw)
          newErrors.shiftStartRaw = translate("StartOfShiftRequired");
        if (!shiftEndRaw)
          newErrors.shiftEndRaw = translate("EndOfShiftRequired");
        if (!selectedStaff)
          newErrors.selectedStaff = translate("StaffRequired");
        if (!effectiveDateTime)
          newErrors.effectiveDateTime = translate("EffectiveDateRequired");
        break;
      default:
        break;
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      showCustomToast(translate("PleaseFillAllRequiredFields"), "error");
    }

    return Object.keys(newErrors).length === 0;
  };

  const handleShiftStartChange = (date) => {
    setShiftStartRaw(date);
  };

  // Gestionnaire de changement pour shiftEnd
  const handleShiftEndChange = (date) => {
    setShiftEndRaw(date);
  };

  // Gestionnaire de changement pour le personnel
  const handleStaffChange = (staff) => {
    setSelectedStaff(staff);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500); // 500ms de délai

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const handleFilesChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.every((file) => file.size <= 50 * 1024 * 1024)) {
      setFiles(selectedFiles);
    } else {
      alert("One or more files exceed the size limit of 50MB.");
    }
  };

  useEffect(() => {
    let newUnsubscribe;

    if (debouncedSearchQuery.length > 0) {
      setLoading(true);

      const search = query(
        collection(db, "clients"),
        where(
          "searchTerms",
          "array-contains-any",
          debouncedSearchQuery.trim().toLowerCase().split(" ")
        ),
        limit(10)
      );

      newUnsubscribe = onSnapshot(search, (snapshot) => {
        const results = [];
        snapshot.forEach((doc) => {
          results.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setClients(results);
        setLoading(false);
      });
    } else {
      setClients([]);
    }

    setUnsubscribe(() => newUnsubscribe);
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [debouncedSearchQuery]);

  const handleFileRemove = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const ClientSearch = ({
    searchQuery,
    setSearchQuery,
    clients,
    setSelectedClient,
    setSelectedClientName,
  }) => {
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedSearchQuery(searchQuery);
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }, [searchQuery]);

    const handleChange = (event) => {
      setSearchQuery(event.target.value);
      setSelectedClient(null);
    };

    const handleSelect = (event, value) => {
      if (value && value.id) {
        setSelectedClient(value);
        setSelectedClientName(`${value.firstName} ${value.lastName}`);
      } else {
        setSelectedClient(null);
        setSelectedClientName("");
      }
    };

    const getStatusClass = (status) => {
      switch (status) {
        case "In House":
          return "ribon-color-inhouse";
        case "Aftercare":
          return "ribon-color-aftercare";
        case "Incoming":
          return "ribon-color-incoming";
        case "Miracles@home":
          return "ribon-color-mathome";
        case "Clinical Assessment":
          return "ribon-color-clinical-assessment";
        case "Continued Therapy":
          return "ribon-color-continued-therapy";
        case "Hot Lead":
          return "ribon-color-hot-lead";
        case "Denied Admission":
          return "ribon-color-denied-admission";
        case "Potential":
          return "ribon-color-potential";
        default:
          return "ribon-color-inactive";
      }
    };

    return (
      <div style={{ padding: "20px" }}>
        <Autocomplete
          freeSolo
          disableClearable
          options={clients}
          getOptionLabel={(option) =>
            `${option.firstName} ${option.lastName} (${option.clientIdentifier})`
          }
          inputValue={searchQuery}
          onInputChange={(event, newInputValue) => {
            setSearchQuery(newInputValue);
          }}
          onChange={handleSelect}
          renderOption={(props, option) => (
            <li
              {...props}
              key={option.id}
              style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #BEBCBC",
              }}
            >
              {option.profilePicture ? (
                <Avatar
                  src={option.profilePicture}
                  style={{ marginRight: "10px", width: "50px", height: "50px" }}
                />
              ) : (
                <Avatar
                  style={{
                    marginRight: "10px",
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#e0e0e0",
                  }}
                >
                  <AccountCircleIcon />
                </Avatar>
              )}
              <div style={{ width: "180px" }}>
                {`${option.firstName} ${option.lastName}`}
              </div>
              <div
                style={{
                  width: "120px",
                  fontSize: "12px",
                }}
                className={`${
                  option.clientStatus === "Clinical Assessment"
                    ? "ribon-container-medium"
                    : "ribon-container-small"
                } ${getStatusClass(option.clientStatus)}`}
              >
                {option.clientStatus}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <div
              ref={params.InputProps.ref}
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <SearchIcon
                style={{
                  position: "absolute",
                  left: "10px",
                  color: "#9e9e9e",
                }}
              />
              <input
                type="text"
                {...params.inputProps}
                onChange={handleChange}
                value={searchQuery}
                placeholder="Search client…"
                style={{
                  width: "100%",
                  height: "45px",
                  borderRadius: "30px",
                  paddingLeft: "40px",
                  background: "#f2f3f8",
                  border: "1px solid #e9e9ea",
                }}
              />
            </div>
          )}
        />
      </div>
    );
  };

  const SelectedClientDisplay = ({ selectedClientName }) =>
    selectedClientName ? (
      <div style={{ marginTop: "10px", fontWeight: "bold" }}>
        {translate("SelectedClient")} {selectedClientName}
      </div>
    ) : null;

  const renderModalContent = (noteType, shouldShowClientSearch) => {
    console.log("selected Client dans modal : ", transferedClient);

    const renderClientSection = () => {
      if (transferedClient) {
        // Si un client est déjà sélectionné, afficher ses informations
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "20px",
            }}
          >
            <div
              style={{ paddingLeft: "20px", color: "black", fontWeight: "600" }}
            >
              {translate("SelectedClient")}
            </div>
            <div
              style={{
                width: "70%",
                marginTop: "10px",
                display: "flex",
                paddingLeft: "20px",
                justifyContent: "flex-start",
                border: "1px solid lightgray",
                borderRadius: "10px",
                marginLeft: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <SmallClientChipV3Large
                firstName={transferedClient.firstName}
                lastName={transferedClient.lastName}
                avatar="1"
                clientPic={transferedClient.profilePicture}
              />
            </div>
          </div>
        );
      } else {
        // Si aucun client n'est sélectionné, afficher la barre de recherche pour sélectionner un client
        return (
          <div>
            <div
              style={{
                width: "70%",
                marginTop: "20px",
                display: "flex",
                paddingLeft: "20px",
              }}
            >
              <SearchBarInModal setSelectedClient={setSelectedClient} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "20px",
                paddingLeft: "25px",
              }}
            >
              <div style={{ color: "black", fontWeight: "600" }}>
                {translate("NoClientSelected")}
              </div>
              {errors.clientName && (
                <div
                  style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                >
                  {errors.clientName}
                </div>
              )}
            </div>
          </div>
        );
      }
    };

    const renderNoteContent = (placeholder, rows) => (
      <div className="line2-column-modal">
        <div
          className="label-textfield-handover"
          style={{
            color: "black",
            fontWeight: "600",
            paddingLeft: "15px",
            paddingBottom: "5px",
          }}
        >
          {translate("NoteContent")}
        </div>
        <TextField
          value={noteContent}
          onChange={(e) => setNoteContent(e.target.value)}
          style={{
            width: "95%",
            borderRadius: "5px",
          }}
          placeholder={placeholder}
          multiline
          rows={rows}
          error={!!errors.noteContent} // Highlight the input if there's an error
          helperText={errors.noteContent}
        />
      </div>
    );

    const renderEffectiveDateTime = () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <div
          className="label-textfield-handover"
          style={{
            color: "black",
            fontWeight: "600",
            marginTop: "20px",
            paddingLeft: "20px",
            paddingBottom: "5px",
          }}
        >
          {translate("EffectiveDateTime")}
        </div>
        <div style={{ paddingLeft: "15px" }}>
          <DatePickerWithTimeComponentNoTitle
            title={translate("EffectiveDateTime")}
            value={effectiveDateTime}
            onChange={(date) => setEffectiveDateTime(date)}
            error={!!errors.effectiveDateTime}
            helperText={errors.effectiveDateTime}
          />
        </div>
      </div>
    );

    const staffOptions = ["Staff Member 1", "Staff Member 2", "Staff Member 3"];

    switch (noteType) {
      case "supportStaffNote":
        return (
          <div>
            {renderClientSection()}
            <div style={{ width: "100%", marginTop: "0px", height: "100%" }}>
              {renderNoteContent(translate("TypeNoteHere"), 14)}
              {renderEffectiveDateTime()}
            </div>
          </div>
        );

      case "endOfShift":
        return (
          <div>
            <div className="line2-column-modal-slider">
              <div
                className="label-textfield"
                style={{
                  color: "black",
                  fontWeight: "600",
                  marginTop: "10px",
                }}
              >
                {translate("NoteContent")}
              </div>
              <TextField
                value={noteContent}
                onChange={(e) => setNoteContent(e.target.value)}
                style={{
                  width: "95%",
                  borderRadius: "5px",
                  // backgroundColor: "#f2eeef",
                }}
                placeholder={translate("TypeNoteHere")}
                multiline
                rows={12}
                error={!!errors.noteContent} // Highlight the input if there's an error
                helperText={errors.noteContent}
              />
            </div>
            <div
              // className="line3-modal-mobile-bis"
              style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "flex-start",
                  alignItems: "flex-start",
                  alignSelf: "flex-start",
                  marginTop: "10px",
                }}
              >
                <div
                  // className="label-textfield"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    marginTop: "10px",
                    paddingLeft: "15px",
                    textAlign: "start",
                  }}
                >
                  {translate("StartOfShift")}
                </div>
                <div style={{ paddingLeft: "5px" }}>
                  <DatePickerWithTimeComponentNoTitle
                    value={shiftStartRaw}
                    onChange={(date) => setShiftStartRaw(date)}
                    title={translate("StartOfShift")}
                    error={!!errors.shiftStartRaw}
                    helperText={errors.shiftStartRaw}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "flex-start",
                  alignItems: "flex-start",
                  alignSelf: "flex-start",
                  marginTop: "10px",
                  marginLeft: "20px",
                }}
              >
                <div
                  // className="label-textfield"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    marginTop: "10px",
                    paddingLeft: "15px",
                  }}
                >
                  {translate("EndOfShift")}
                </div>

                <DatePickerWithTimeComponentNoTitle
                  value={shiftEndRaw}
                  onChange={(date) => setShiftEndRaw(date)}
                  title={translate("EndOfShift")}
                  error={!!errors.shiftEndRaw}
                  helperText={errors.shiftEndRaw}
                />
              </div>
            </div>
            <div
              // className="line3-modal-mobile-bis"
              style={{ paddingLeft: "20px", marginTop: "20px" }}
            >
              <div
                // className="label-textfield"
                style={{
                  color: "black",
                  fontWeight: "600",
                  marginTop: "10px",
                  paddingLeft: "5px",
                }}
              >
                {translate("SelectStaff")}
              </div>
              <div style={{ width: "50%" }}>
                <DropDownSpecificStaffV2
                  value={selectedStaff}
                  onChange={(staff) => setSelectedStaff(staff)}
                  error={!!errors.selectedStaff}
                  helperText={errors.selectedStaff}
                  // title={translate("SelectStaff")}
                />
              </div>
            </div>

            <div style={{ paddingLeft: "10px", marginTop: "20px" }}>
              <div
                className="label-textfield-handover"
                style={{
                  color: "black",
                  fontWeight: "600",
                  paddingLeft: "10px",
                }}
              >
                {translate("EffectiveDateTime")}
              </div>
              <div style={{ paddingLeft: "5px" }}>
                <DatePickerWithTimeComponentNoTitle
                  value={effectiveDateTime}
                  onChange={(date) => setEffectiveDateTime(date)}
                  title={translate("EffectiveDateTime")}
                  error={!!errors.effectiveDateTime}
                  helperText={errors.effectiveDateTime}
                />
              </div>
            </div>
          </div>
        );

      case "aftercareNote":
      case "familyNote":
      case "quickNote":
      case "therapyNote":
        return (
          <div>
            {renderClientSection()}
            <div
              style={{
                width: "100%",
                marginTop: "0px",
                height: "75%",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {renderNoteContent("Type quick session note here", 14)}
              {renderEffectiveDateTime()}
            </div>
          </div>
        );

      case "groupNote":
        return (
          <div>
            {/* {renderClientSection()} */}
            <div className="inputAndLabelModalContainerTitle2">
              <div style={{ paddingLeft: "10px", paddingRight: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="label-textfield-handover"
                    style={{
                      color: "black",
                      fontWeight: "600",
                      paddingLeft: "10px",
                      paddingBottom: "5px",
                    }}
                  >
                    {translate("GroupTopic")}
                  </div>
                  <div style={{ marginLeft: "15px" }}>
                    <Input
                      variant="outlined"
                      // placeholder="Search client…"
                      onChange={(e) => setGroupTopic(e.target.value)}
                      value={groupTopic}
                      style={{
                        width: "300px",
                        height: "40px",
                        borderRadius: "5px",
                        background: "#FFFFFF",
                        border: "1px solid #BEBCBC",
                        marginTop: "2px",
                        paddingLeft: "8px",
                      }}
                      error={!!errors.groupTopic}
                      helperText={errors.groupTopic}
                      // placeholder={props.placeholder}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "20px",
                  width: "100%",
                }}
              >
                <div
                  className="line3-modal"
                  style={{
                    paddingLeft: "25px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignContent: "start",
                    width: "100%",
                  }}
                >
                  <div
                    className="label-textfield-handover"
                    style={{
                      color: "black",
                      fontWeight: "600",
                      marginTop: "10px",
                    }}
                  >
                    {translate("GroupDateStartTime")}
                  </div>
                  <div
                    style={{
                      paddingTop: "10px",
                      width: "100%",
                    }}
                  >
                    <DatePickerWithTimeComponentNoTitleNoMargin
                      isRequired={true}
                      value={effectiveDateTime}
                      title="Group Date and start time"
                      width="400px"
                      onChange={(date) => setEffectiveDateTime(date)}
                      leftMargin="none"
                    />
                  </div>
                </div>
                <div
                  style={{
                    paddingLeft: "15px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="label-textfield-handover"
                    style={{
                      color: "black",
                      fontWeight: "600",
                      marginTop: "20px",
                      paddingLeft: "10px",
                    }}
                  >
                    {translate("TypeOfGroup")}
                  </div>
                  <div style={{ width: "70%" }}>
                    <DropDownComponentSortedNoTitle
                      isRequired={true}
                      api="groupType"
                      title="Type of group"
                      value={groupType}
                      onChange={(type) => setGroupType(type)}
                      error={!!errors.groupType}
                      helperText={errors.groupType}
                    />
                    {groupType === "Other" && (
                      <InputComponentNoTitleNomargin
                        title={translate("IfOtherDescribe")}
                        width="auto"
                        value={otherTypeGroupNote}
                        onChange={(e) => setOtherTypeGroupNote(e.target.value)}
                        placeholder={translate("IfOtherDescribe")}
                      />
                    )}
                  </div>
                </div>
              </div>
              {renderNoteContent(translate("TypeGroupNoteHere"), 10)}
              <div className="line2-column-modal">
                <div
                  className="label-textfield"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    paddingLeft: "0px",
                    paddingBottom: "5px",
                  }}
                >
                  {translate("ClientsNotInAttendance")}
                  <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                </div>
                <TextField
                  style={{ width: "95%", borderRadius: "5px" }}
                  placeholder={translate("TypeNamesAndReason")}
                  multiline
                  rows={4}
                  value={notInAttendance}
                  onChange={(e) => setNotInAttendance(e.target.value)}
                  error={!!errors.notInAttendance}
                  helperText={errors.notInAttendance}
                />
              </div>
            </div>
          </div>
        );

      case "handoverNote":
        return (
          <div>
            <div
              style={{
                width: "100%",
                marginTop: "0px",
                height: "75%",
                overflowX: "hidden",
              }}
            >
              <div className="line2-column-modal-handover">
                <div
                  className="tab-form-container-handover"
                  style={{ marginTop: "0px" }}
                >
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("ImmigrationAppointment")}
                    </div>
                    <TextField
                      value={immigrationAppointment}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) =>
                        setImmigrationAppointment(e.target.value)
                      }
                      error={!!errors.immigrationAppointment}
                      helperText={errors.immigrationAppointment}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("DischargesFlights")}
                    </div>
                    <TextField
                      value={dischargeSwot}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setDischargeSwot(e.target.value)}
                      error={!!errors.dischargeSwot}
                      helperText={errors.dischargeSwot}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("NewClients")}
                    </div>
                    <TextField
                      value={newClients}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setNewClients(e.target.value)}
                      error={!!errors.newClients}
                      helperText={errors.newClients}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("PsychiatristAppointments")}
                    </div>
                    <TextField
                      value={medicalInfos}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setMedicalInfos(e.target.value)}
                      error={!!errors.medicalInfos}
                      helperText={errors.medicalInfos}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("HospitalAppointments")}
                    </div>
                    <TextField
                      value={hospitalAppointment}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setHospitalAppointment(e.target.value)}
                      error={!!errors.hospitalAppointment}
                      helperText={errors.hospitalAppointment}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("Outings")}
                    </div>
                    <TextField
                      value={residentialClientMovements}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) =>
                        setResidentialClientMovements(e.target.value)
                      }
                      error={!!errors.residentialClientMovements}
                      helperText={errors.residentialClientMovements}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("ZoomSessionAssistance")}
                    </div>
                    <TextField
                      value={zoomSessionAssistance}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setZoomSessionAssistance(e.target.value)}
                      error={!!errors.zoomSessionAssistance}
                      helperText={errors.zoomSessionAssistance}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("StaffToday")}
                    </div>
                    <TextField
                      value={staffToday}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setStaffToday(e.target.value)}
                      error={!!errors.staffToday}
                      helperText={errors.staffToday}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("Overnight")}
                    </div>
                    <TextField
                      value={overnight}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setOvernight(e.target.value)}
                      error={!!errors.overnight}
                      helperText={errors.overnight}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("NumberOfClients")}
                    </div>
                    <TextField
                      value={numberOfClient}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setNumberOfClient(e.target.value)}
                      error={!!errors.numberOfClient}
                      helperText={errors.numberOfClient}
                    />
                  </div>
                </div>
                <div className="tab-form-container-handover">
                  <div
                    className="textfield-and-label-container100"
                    style={{ marginBottom: "10px" }}
                  >
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("Swot")}
                    </div>
                    <TextField
                      value={swot}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder="..."
                      multiline
                      rows={5}
                      onChange={(e) => setSwot(e.target.value)}
                      error={!!errors.swot}
                      helperText={errors.swot}
                    />
                  </div>
                  <div className="textfield-and-label-container100">
                    <div
                      className="label-textfield-handover"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {translate("GeneralAnnouncement")}
                    </div>
                    <TextField
                      value={generalAnnouncement}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      placeholder={translate("TypeNoteHere")}
                      multiline
                      rows={5}
                      onChange={(e) => setGeneralAnnouncement(e.target.value)}
                      error={!!errors.generalAnnouncement}
                      helperText={errors.generalAnnouncement}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  paddingLeft: "10px",
                  marginTop: "15px",
                  marginBottom: "40px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  className="label-textfield-handover"
                  style={{
                    color: "black",
                    fontWeight: "600",
                    paddingLeft: "10px",
                  }}
                >
                  {translate("EffectiveDateTime")}
                </div>
                <div style={{ paddingLeft: "5px" }}>
                  <DatePickerWithTimeComponentNoTitle
                    value={effectiveDateTime}
                    onChange={(date) => setEffectiveDateTime(date)}
                    title={translate("EffectiveDateTime")}
                    error={!!errors.effectiveDateTime}
                    helperText={errors.effectiveDateTime}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      case "medicalNote":
        return (
          <div>
            {renderClientSection()}
            <div className="line2-column-modal">
              <div
                className="label-textfield-handover"
                style={{
                  color: "black",
                  fontWeight: "600",
                  paddingLeft: "15px",
                  paddingBottom: "5px",
                }}
              >
                {translate("NoteContent")}
              </div>
              <TextField
                name="quickNote"
                value={noteContent}
                onChange={(e) => setNoteContent(e.target.value)}
                style={{
                  width: "95%",
                  borderRadius: "5px",
                }}
                placeholder={translate("TypeNoteHere")}
                multiline
                rows={14}
                error={!!errors.noteContent} // Highlight the input if there's an error
                helperText={errors.noteContent}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "50%",
                flexDirection: "column",
              }}
            >
              <div
                className="label-textfield-handover"
                style={{
                  color: "black",
                  fontWeight: "600",
                  marginTop: "20px",
                  paddingLeft: "20px",
                  paddingBottom: "5px",
                  width: "350px",
                }}
              >
                {translate("EffectiveDateTime")}
              </div>
              <div style={{ paddingLeft: "15px" }}>
                <DatePickerWithTimeComponentNoTitle
                  title={translate("EffectiveDateTime")}
                  value={effectiveDateTime}
                  onChange={(date) => setEffectiveDateTime(date)}
                  error={!!errors.effectiveDateTime}
                  helperText={errors.effectiveDateTime}
                />
              </div>
            </div>
            <div className="main-upload-container">
              <UploaderDocForNoteV2
                handleFileChange={handleFilesChange}
                files={files}
                handleFileRemove={handleFileRemove}
              />
              {/* {fileUrls.length > 0 && (
                <UploadedFilesComponentV2
                  fileLinks={fileUrls}
                  deleteFile={(fileUrl, fileName) =>
                    handleFileRemove(fileUrl, fileName, noteId)
                  }
                  noteId={noteId}
                />
              )} */}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const handleSaveNote = async () => {
    if (!validateFields()) {
      return;
    }

    setIsLoading(true);
    const shiftStart = shiftStartRaw;
    const shiftEnd = shiftEndRaw;
    const staff = selectedStaff;

    try {
      const currentUserEmail = currentUser
        ? currentUser.displayName
        : "Unknown";
      handleSave(
        noteType,
        noteContent,
        effectiveDateTime,
        files,
        fileUrls,
        selectedClient,
        shiftStart,
        shiftEnd,
        staff,
        currentUserEmail, // Pass the creator's email or name
        currentUserEmail,
        generalAnnouncement, // Général
        groupTopic, // Groupe
        groupType,
        notInAttendance,
        immigrationAppointment, // Rendez-vous d'immigration
        dischargeSwot, // Décharges
        newClients, // Nouveaux clients
        medicalInfos, // Infos médicales
        hospitalAppointment, // Rendez-vous à l'hôpital
        residentialClientMovements, // Mouvements des clients résidentiels
        zoomSessionAssistance, // Assistance à la session Zoom
        staffToday, // Personnel aujourd'hui
        overnight, // Nuitée
        numberOfClient, // Nombre de clients
        swot // SWOT
      );
      onClose();
      showCustomToast(translate("NoteAddedSuccess"), "success");
    } catch (error) {
      console.error("Error saving note:", error);
      showCustomToast("Problem when saving note!", "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  const resetForm = () => {
    setNoteContent("");
    setEffectiveDateTime(null);
    setFiles([]);
    setFileUrls([]);
    setShiftStartRaw(null);
    setShiftEndRaw(null);
    setSelectedStaff("");
    setGroupTopic("");
    setGroupType("");
    setOtherTypeGroupNote("");
    setRelationship("");
    setNotInAttendance("");
    setImmigrationAppointment("");
    setDischargeSwot("");
    setNewClients("");
    setMedicalInfos("");
    setHospitalAppointment("");
    setResidentialClientMovements("");
    setZoomSessionAssistance("");
    setStaffToday("");
    setOvernight("");
    setNumberOfClient("");
    setSwot("");
    setGeneralAnnouncement("");
    setSearchQuery("");
    setDebouncedSearchQuery("");
    setErrors({});

    // Ne réinitialisez pas selectedClient s'il est fourni en prop
    if (!transferedClient) {
      setSelectedClient(null);
      setSelectedClientName("");
    }
  };

  const formatNoteType = (noteType) => {
    return noteType
      .replace(/([A-Z])/g, " $1") // Add space before capital letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="note-modal-title"
      aria-describedby="note-modal-description"
      closeAfterTransition
    >
      <Slide direction="left" in={open}>
        <Box
          sx={{
            position: "fixed",
            top: "5%",
            right: 0,
            width: "30%",
            height: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            marginRight: "15px",
            pl: 0,
            pr: 0,
            borderRadius: "15px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
              borderBottom: "1px solid lightgray",
            }}
          >
            <div
              id="note-modal-title"
              style={{
                margin: 0,
                paddingLeft: "35px",
                paddingTop: "10px",
                paddingBottom: "10px",
                fontSize: "22px",
                fontWeight: "700",
              }}
            >
              {/* {translate(
                `Add${noteType.charAt(0).toUpperCase() + noteType.slice(1)}Note`
              )} */}
              {translate(`${translate("Add")} ${formatNoteType(noteType)}`)}
            </div>
            <div style={{ paddingRight: "15px" }}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </Box>
          {/* <Box
            id="note-modal-description"
            sx={{ flexGrow: 1, overflowY: "auto", mt: 0, pl: 2, pr: 2 }}
          >
            
            {renderModalContent(
              noteType,
              [
                "aftercareNote",
                "familyNote",
                "quickNote",
                "therapyNote",
                "medicalNote",
              ].includes(noteType)
            )}
          </Box> */}
          <Box
            id="note-modal-description"
            sx={{ flexGrow: 1, overflowY: "auto", mt: 0, pl: 2, pr: 2 }}
          >
            {renderModalContent(
              noteType,
              [
                "aftercareNote",
                "medicalNote",
                "familyNote",
                "quickNote",
                "supportStaffNote",
                "therapyNote",
              ].includes(noteType)
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              borderTop: "1px solid lightgray",
              padding: "10px 20px",
              mt: "auto",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: "10px" }}
              onClick={onClose}
            >
              {translate("Cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveNote}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : translate("Save")}
            </Button>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default NotesAddModalClientSelected;
