import "../App.css";
import { AuthProvider } from "../contexts/AuthContext";
import Signup from "./Signup";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../component/Login";
import Clients from "./Clients";
import Prescriptions from "./Prescriptions";
import Dashboard from "./Dashboard";
import Notes from "./Notes";
import Documents from "./Documents";
import Tutorials from "./Tutorials";
import Metrics from "./Metrics";
import Accounting from "./Accounting";
import Account from "./Account";
import Client from "./Client";
import ProtectedRoute from "./ProtectedRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./ForgotPassword";
import Roles from "./Roles";
import ContactsList from "./ContactsList";
import MobileNavbar from "../component/MobileNavbar";
import Export from "./Export";
import Availability from "./Availability";
import Salesteam from "./Salesteam";
import Cheatsheet from "./Cheatsheet";
import SupportDashboard from "./SupportDashboard";
import Homepage from "./Homepage";
import { LanguageProvider } from "../context/LanguageProvider";
import ArtificialIntelligence from "./ArtificialIntelligence";
import Crm from "./Crm";
import Medication from "./Medication";
import Support from "./Support";
import Invoicing from "./Invoicing";
import RoomLayout from "./RoomLayout";
import RehappPrompts from "./RehappPrompts";

function App() {
  return (
    <Router>
      <LanguageProvider>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <AuthProvider>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Homepage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/clients"
              element={
                <ProtectedRoute>
                  <Clients />
                </ProtectedRoute>
              }
            />

            <Route
              exact
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />

            <Route
              path="/notes"
              element={
                <ProtectedRoute>
                  <Notes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/prescriptions"
              element={
                <ProtectedRoute>
                  <Prescriptions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/documents"
              element={
                <ProtectedRoute>
                  <Documents />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tutorials"
              element={
                <ProtectedRoute>
                  <Tutorials />
                </ProtectedRoute>
              }
            />
            <Route
              path="/metrics"
              element={
                <ProtectedRoute>
                  <Metrics />
                </ProtectedRoute>
              }
            />
            <Route
              path="/accounting"
              element={
                <ProtectedRoute>
                  <Accounting />
                </ProtectedRoute>
              }
            />
            <Route
              path="/account"
              element={
                <ProtectedRoute>
                  <Account />
                </ProtectedRoute>
              }
            />
            <Route
              path="/client/:id"
              element={
                <ProtectedRoute>
                  <Client />
                </ProtectedRoute>
              }
            />
            <Route
              path="/forgotpassword"
              element={
                <ProtectedRoute>
                  <ForgotPassword />
                </ProtectedRoute>
              }
            />
            <Route
              path="/roles"
              element={
                <ProtectedRoute>
                  <Roles />
                </ProtectedRoute>
              }
            />

            <Route
              path="/stafflist"
              element={
                <ProtectedRoute>
                  <ContactsList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/menumobile"
              element={
                <ProtectedRoute>
                  <MobileNavbar />
                </ProtectedRoute>
              }
            />

            <Route
              path="/export"
              element={
                <ProtectedRoute>
                  <Export />
                </ProtectedRoute>
              }
            />

            <Route
              path="/availability"
              element={
                <ProtectedRoute>
                  <Availability />
                </ProtectedRoute>
              }
            />

            <Route
              path="/salesteam"
              element={
                <ProtectedRoute>
                  <Salesteam />
                </ProtectedRoute>
              }
            />

            <Route
              path="/cheatsheet"
              element={
                <ProtectedRoute>
                  <Cheatsheet />
                </ProtectedRoute>
              }
            />

            <Route
              path="/supportdashboard"
              element={
                <ProtectedRoute>
                  <SupportDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ai"
              element={
                <ProtectedRoute>
                  <ArtificialIntelligence />
                </ProtectedRoute>
              }
            />

            <Route
              path="/crm"
              element={
                <ProtectedRoute>
                  <Crm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/medication"
              element={
                <ProtectedRoute>
                  <Medication />
                </ProtectedRoute>
              }
            />
            <Route
              path="/support"
              element={
                <ProtectedRoute>
                  <Support />
                </ProtectedRoute>
              }
            />

            <Route
              path="/roomlayout"
              element={
                <ProtectedRoute>
                  <RoomLayout />
                </ProtectedRoute>
              }
            />

            <Route
              path="/invoicing"
              element={
                <ProtectedRoute>
                  <Invoicing />
                </ProtectedRoute>
              }
            />

            <Route
              path="/prompts"
              element={
                <ProtectedRoute>
                  <RehappPrompts />
                </ProtectedRoute>
              }
            />
          </Routes>
        </AuthProvider>
      </LanguageProvider>
    </Router>
  );
}

export default App;
