export const formatNotesByType = (notes, desiredNoteTypes) => {
  const notesByType = {};
  const generalNotes = [];

  notes.forEach((note) => {
    const noteType = note.noteType || "Unknown";
    const content = note.noteContent || "No content available";
    const effectiveDateAndTime = note.effectiveDateTime || "Unknown date";

    // Vérifier si la note est générale (handoverNote ou groupNote)
    if (noteType === "handoverNote" || noteType === "groupNote") {
      generalNotes.push(
        `Type: ${noteType}\nContent: ${content}\nEffective Date and Time: ${effectiveDateAndTime}`
      );
    } else {
      // Traiter comme spécifique au client
      const clientName = note.clientFirstName || "Unknown";
      const clientId = note.clientIdentifier || note.clientId || "Unknown";

      // Initialiser les objets imbriqués si nécessaire
      if (!notesByType[noteType]) {
        notesByType[noteType] = {};
      }

      if (!notesByType[noteType][clientId]) {
        notesByType[noteType][clientId] = [];
      }

      notesByType[noteType][clientId].push(
        `Client: ${clientName} (ID: ${clientId})\nContent: ${content}\nEffective Date and Time: ${effectiveDateAndTime}`
      );
    }
  });

  let formattedNotes = "";

  // Formater les notes spécifiques au client
  for (const [type, clients] of Object.entries(notesByType)) {
    for (const [clientId, notesList] of Object.entries(clients)) {
      formattedNotes += `Notes type "${type}" du client : ${clientId}\n${notesList.join(
        "\n\n"
      )}\n\n`;
    }
  }

  // Formater les notes générales séparément
  if (generalNotes.length > 0) {
    formattedNotes += `Notes Générales:\n${generalNotes.join("\n\n")}\n\n`;
  }

  return formattedNotes;
};

// Fonction pour générer le résumé pour un client spécifique
export const generateSummaryForSelectedClient = (
  selectedClientInfo,
  selectedClientNotes,
  selectedClientPreAdmission,
  selectedClientTreatmentPlans,
  desiredNoteTypes
) => {
  const notesSummary =
    selectedClientNotes.length > 0
      ? formatNotesByType(selectedClientNotes, desiredNoteTypes)
      : "No notes available.";

  return `
      Here is the selected client information:
      ${JSON.stringify(selectedClientInfo, null, 2)}
      
      Here are the notes for the selected client:
      ${notesSummary}
      
      Pre-admission Data:
      ${
        selectedClientPreAdmission
          ? JSON.stringify(selectedClientPreAdmission, null, 2)
          : "No pre-admission data available."
      }
      
      Treatment Plans:
      ${
        selectedClientTreatmentPlans
          ? JSON.stringify(selectedClientTreatmentPlans, null, 2)
          : "No treatment plans available."
      }
      **For any questions related to room availability, please visit https://app.rehapp.app/roomlayout.**
      `;
};

export const generateSummaryForAllClients = (
  clientInfo,
  clientNotes,
  clientMetrics,
  desiredNoteTypes
) => {
  const inHouseClients = clientInfo.filter(
    (client) => client.clientStatus === "In House"
  );
  console.log("*** Clients Notes récupérées: ", clientNotes);

  const inHouseClientsList = inHouseClients
    .map((client) => `- ${client.firstName} (${client.clientIdentifier})`)
    .join("\n");

  const addictionDistribution = {};
  inHouseClients.forEach((client) => {
    const addiction = client.primaryAddiction || "Unknown";
    addictionDistribution[addiction] =
      (addictionDistribution[addiction] || 0) + 1;
  });

  const addictionDistributionList = Object.entries(addictionDistribution)
    .map(([addiction, count]) => `- ${addiction}: ${count}`)
    .join("\n");

  const dischargeByMonth = {};
  inHouseClients.forEach((client) => {
    const dischargeDate = client.projectedResidentialDischargeDate;
    if (dischargeDate) {
      const monthYear = new Date(dischargeDate).toISOString().slice(0, 7);
      dischargeByMonth[monthYear] = dischargeByMonth[monthYear] || [];
      dischargeByMonth[monthYear].push(client.clientIdentifier);
    }
  });

  const dischargeSummary = Object.entries(dischargeByMonth)
    .map(
      ([monthYear, clients]) =>
        `For ${monthYear}, the In House clients with scheduled discharges are: ${clients.join(
          ", "
        )}`
    )
    .join("\n");

  const promptIntroduction = `
      Here is the list of In House clients with their client identifiers:
      ${inHouseClientsList}
      
      Scheduled discharges by month for In House clients:
      ${dischargeSummary}
      
      Addiction distribution among In House clients:
      ${addictionDistributionList}
      `;

  const clientInfoSummary = clientInfo
    .map((info) => {
      const clientName = info.firstName || "Unknown";
      const clientIdentifier = info.clientIdentifier || "Unknown";
      const clientStatus = info.clientStatus || "Unknown";
      const age = info.age ? `${info.age} years old` : "Unknown age";
      const gender = info.gender || "Unknown gender";
      const phoneNumber = info.phoneNumber || "Unknown phone number";
      const roomNumber =
        info.clientStatus === "In House"
          ? info.roomNumber || "Not assigned"
          : null;
      const selectedTherapist = info.selectedTherapist || "Unknown therapist";
      const durationOfStay = info.durationOfStay
        ? `${info.durationOfStay} days`
        : "Unknown duration";
      const dob = info.dob
        ? new Date(info.dob).toISOString()
        : "Unknown date of birth";

      const admissionDate = info.dateOfAdmission
        ? new Date(info.dateOfAdmission).toISOString()
        : "Unknown admission date";
      const dischargeDate = info.projectedResidentialDischargeDate
        ? new Date(info.projectedResidentialDischargeDate).toISOString()
        : "Unknown discharge date";

      const primaryAddiction = info.primaryAddiction || "Unknown addiction";
      const country = info.country || "Unknown country";

      // Filtrer les notes pour ce client et les types de notes souhaités
      const clientNotesForThisClient = clientNotes.filter(
        (note) =>
          (note.clientId === info.id ||
            note.clientIdentifier === info.clientIdentifier) &&
          desiredNoteTypes.includes(note.noteType)
      );

      // Grouper les notes du client par type de note
      const notesByType = {};

      clientNotesForThisClient.forEach((note) => {
        const noteType = note.noteType || "Unknown";
        if (!notesByType[noteType]) {
          notesByType[noteType] = [];
        }
        notesByType[noteType].push(note);
      });

      let formattedNotesText = "";

      // Formater les notes par type pour ce client
      for (const [type, notesList] of Object.entries(notesByType)) {
        formattedNotesText += `For client identifier ${clientIdentifier}, the ${type} are the following:\n`;
        formattedNotesText += notesList
          .map(
            (note) =>
              `{Note ID: ${note.id || "Unknown ID"}, Content: ${
                note.noteContent || "No content available"
              }, Effective Date and Time: ${
                note.effectiveDateTime || "Unknown date"
              }}`
          )
          .join("\n\n");
        formattedNotesText += "\n\n";
      }

      return `${clientName}, with client identifier ${clientIdentifier}, has the status "${clientStatus}", 
      Gender: ${gender}, d.o.b.: ${dob}, Therapist: ${selectedTherapist},
      Admitted on ${admissionDate}, projected to be discharged on ${dischargeDate},
      Duration of Stay: ${durationOfStay},
      Primary Addiction: ${primaryAddiction}, Country: ${country},
      ${roomNumber ? `Room Number: ${roomNumber}.` : ""}
      ${formattedNotesText}`;
    })
    .join("\n\n");

  const clientMetricsSummary = `
      Here are the client metrics:
      - In House Count: ${clientMetrics.inHouseCount}
      - Other Status Count: ${clientMetrics.otherStatusCount}
      - Male Count: ${clientMetrics.maleCount}
      - Female Count: ${clientMetrics.femaleCount}
      
      Status Distribution:
      ${Object.entries(clientMetrics.statusDistribution)
        .map(([status, count]) => `  - ${status}: ${count}`)
        .join("\n")}
      
      In House Primary Addiction Distribution:
      ${Object.entries(clientMetrics.inHousePrimaryAddictionDistribution)
        .map(([addiction, count]) => `  - ${addiction}: ${count}`)
        .join("\n")}
      
      All Clients Primary Addiction Distribution:
      ${Object.entries(clientMetrics.allPrimaryAddictionDistribution)
        .map(([addiction, count]) => `  - ${addiction}: ${count}`)
        .join("\n")}
      `;

  // Formater les notes pour tous les clients
  const notesSummary =
    clientNotes.length > 0
      ? formatNotesByType(clientNotes, desiredNoteTypes)
      : "No notes available.";

  return `
      ${promptIntroduction}
      Here is the global client info:
      ${clientInfoSummary} 
  
      ${clientMetricsSummary}
      
      **the link to see the room avalaible is here :  https://app.rehapp.app/roomlayout.**
      answer to all the questions about rooms availability with this link. 
      `;
};
